.file-group-section {
    background: var(--page-background-colour);
}

.file-group-section>summary {
    list-style: none;
}

.file-group-section h2 {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    gap: 0.5rem;
    border-bottom: 1px solid;
    padding-block-end: 0.25rem;
}

.admin .file-group-section h2:not(:hover) .btn {
    visibility: hidden;
}

.group-name {
    flex: 1 1 auto;
    display: flex;
    gap: 1rem;
}

.group-name-error {
    font-size: 1rem;
    color: var(--warning-colour);
}

.document-list {
    margin: 0;
    padding: 0;
}

.document-list .smooth-dnd-stretcher-element {
    color: var(--site-background-colour);
    background: var(--page-background-colour);
    border: 5px dashed;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.document-list .smooth-dnd-stretcher-element::before {
    content: "Drop here";
}

.holding-area-blurb {
    margin-block-start: -0.5rem;
    font-size: 90%;
}

.empty-group-warming {
    font-size: 0.9rem;
}

.holding-section {
    background: var(--holding-section-background);
    outline: 1rem solid var(--holding-section-background);
    margin-block-end: 2rem;
}