.admin.saving::before {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    content: "Working...";
    color: var(--site-background-colour);
    font-size: 3rem;
    font-weight: 500;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
}

.group-controls {
    display: flex;
    gap: 1rem;
}

.group-controls>.file-drop-zone {
    margin-block: 0;
    flex: 1 1 auto;
}

.group-controls>:last-child {
    align-self: center;
}

#file-upload {
    opacity: 0;
    position: absolute;
    left: -9999rem;
}

.smooth-dnd-stretcher-element {
    color: var(--site-background-colour);
    background: var(--page-background-colour);
    border: 5px dashed;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.smooth-dnd-stretcher-element::before {
    content: "Drop here";
}