.drag-handle {
    --size: 1.4rem;
    --colour: #999;
    width: var(--size);
    aspect-ratio: 3 / 4;
    margin-inline-end: 1rem;
    position: relative;
    top: calc(var(--size) * -0.2);
    cursor: move;
    display: inline-block;
}

.drag-handle::after {
    content: "";
    display: block;
    width: 25%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--colour);
    position: absolute;
    inset: 10% 20%;
    box-shadow:
        calc(var(--size) * 0.4) 0 var(--colour),
        0 calc(var(--size) * 0.4) var(--colour),
        calc(var(--size) * 0.4) calc(var(--size) * 0.4) 0 var(--colour),
        0 calc(var(--size) * 0.8) 0 var(--colour),
        calc(var(--size) * 0.4) calc(var(--size) * 0.8) 0 var(--colour);
}