.file-drop-zone {
    padding: 1rem;
    border: 5px dashed;
    border-radius: 15px;
    display: flex;
    gap: 1rem;
    font-size: 1.7rem;
    color: #ccc;
    background-clip: padding-box;
    background-color: var(--page-background-colour);
    align-items: center;
}

.file-drop-zone.drag-active {
    background-color: var(--success-colour);
    color: #444;
}

.file-drop-zone .upload-icon {
    aspect-ratio: 1;
    width: 3rem;
}

.Toastify {
    font-size: 1rem;
}