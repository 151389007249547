:root {
  --primary-colour: #209cee;
  --warning-colour: #d64933;
  --success-colour: #49d633;
  --site-background-colour: #ccc;
  --page-background-colour: #eee;
  --table-row-border-colour: #ddd;
  --page-text-colour: #444;
  --heading-colour: #666;
  --holding-section-background: #ddd;

  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.1),
    0px 0.8px 0.9px -1.2px hsl(var(--shadow-color) / 0.1),
    0px 2px 2.3px -2.5px hsl(var(--shadow-color) / 0.1);
  --shadow-elevation-medium:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.11),
    0px 1.6px 1.8px -0.8px hsl(var(--shadow-color) / 0.11),
    0px 4.1px 4.6px -1.7px hsl(var(--shadow-color) / 0.11),
    0px 10px 11.3px -2.5px hsl(var(--shadow-color) / 0.11);
  --shadow-elevation-high:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.1),
    0px 2.9px 3.3px -0.4px hsl(var(--shadow-color) / 0.1),
    0px 5.5px 6.2px -0.7px hsl(var(--shadow-color) / 0.1),
    0px 9px 10.1px -1.1px hsl(var(--shadow-color) / 0.1),
    0px 14.3px 16.1px -1.4px hsl(var(--shadow-color) / 0.1),
    0px 22.4px 25.2px -1.8px hsl(var(--shadow-color) / 0.1),
    0.1px 34px 38.3px -2.1px hsl(var(--shadow-color) / 0.1),
    0.1px 50.1px 56.4px -2.5px hsl(var(--shadow-color) / 0.1);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  background: var(--site-background-colour);
}

body {
  margin: 1rem auto;
  max-width: 1200px;
  background: var(--page-background-colour);
  color: var(--page-text-colour);
  font-family: "San-Francisco", Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 200;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
  font-weight: 200;
  color: var(--heading-colour);
}

h1 {
  margin: 0 0 2rem 0;
  font-size: 1.8rem;
}

h2 {
  margin: 2rem 0 1rem 0;
  font-size: 1.4rem;
}

.form-group {
  overflow: hidden;
}

.form-group label {
  display: block;
  margin: 0.3rem 0;
}

.form-group input,
.form-group select {
  font-size: 1rem;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="datetime-local"],
.form-group input[type="password"],
.form-group select {
  width: 100%;
  padding: 0.6rem 0.5rem;
  border: 1px solid var(--page-text-colour);
  border-radius: 5px;
}

button,
.btn {
  padding: 0.6rem 0.9rem;
  font-size: 1rem;
  border: 1px solid var(--primary-colour);
  background: var(--primary-colour);
  color: #fff;
  border-radius: 3px;
}

button .icon,
.btn .icon {
  height: 1rem;
  vertical-align: baseline;
  color: var(--page-text-colour);
}

button.delete,
.btn.delete {
  background: var(--warning-colour);
  border-color: var(--warning-colour);
  color: #fff;
}

button.btn-link,
.btn.btn-link {
  background: none;
  border: none;
  color: var(--page-text-colour);
}

button.btn-outline,
.btn.btn-outline {
  background: var(--page-background-colour);
  color: var(--page-text-colour);
  border-color: currentColor;
}

button[disabled],
.btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  height: 1.4rem;
  width: auto;
}

.icon.icon-large {
  height: 2.1rem;
}

.logout {
  color: #999;
  float: right;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

table caption {
  caption-side: top;
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
}

table th {
  font-weight: 600;
  white-space: nowrap;
  background: var(--site-background-colour);
}

table th,
table td {
  text-align: left;
  padding: 0.8rem;
}

table tbody td {
  border-bottom: 2px solid var(--table-row-border-colour);
}


#filestouploadtable.hidden+.form-group {
  display: none;
}

.r {
  text-align: right;
}

.c {
  text-align: center;
}

.footer td {
  font-weight: 600;
}

.hidden {
  display: none;
}

details {
  margin-top: 2rem;
}

@media (max-width: 480px) {
  body {
    font-size: 1rem;
    padding: 1rem;
  }

  .login {
    min-height: 100vh;
  }

  .login img {
    float: right;
    margin: -1rem -1rem 0 0;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  th,
  td {
    padding: 1rem 0.5rem;
  }
}