.login {
    margin: 1rem auto;
    max-width: 480px;
}

.login img {
    float: right;
}

.login form {
    clear: both;
}

.form-group.login-button {
    margin-block-start: 1rem;
    display: flex;
    justify-content: flex-end;
}

.form-group.login-button button {
    position: relative;
}

.form-group.login-button button:disabled::after,
.form-group.login-button button:disabled::before {
    content: "";
    position: absolute;
}

.form-group.login-button button:disabled::before {
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.form-group.login-button button:disabled::after {
    --size: 1.4rem;
    width: var(--size);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    border: 3px solid var(--page-background-colour);
    border-left: 3px solid transparent;
    animation: spin 1.4s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: calc(var(--size) / -2);
    margin-left: calc(var(--size) / -2);
}

@keyframes spin {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}