.document-list-item {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 2px solid #ddd;
    padding-block: 0.5rem;
    background: var(--page-background-colour);
}

.document-list-item>.icon {
    margin: 0 0.4rem 0 0;
    flex: 0 0 1.8rem;
    color: #666;
}

.document-list-item>.file-link {
    flex: 1 1 auto;
}

.document-list-item>.move {
    flex: 0 0 2rem;
}

.admin .document-list-item:not(:hover) .btn {
    visibility: hidden;
}

.info-message {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
}

.smooth-dnd-ghost {
    opacity: 0.7;
}