.file-details-admin {
    flex: 1 1 auto;
    display: flex;
    gap: 1rem;
}

.file-details-admin>.form-group {
    flex: 1 1 auto;
}

.file-details-admin .btn {
    align-self: end;
}

.file-details-admin label {
    font-size: 0.8rem;
}